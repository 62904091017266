<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="pageArray"
    :page-size="pageSizes"
    :layout="layout"
    :total="total"
    class="pagination"
    v-if="total>0"
  ></el-pagination>
</template>

<script>
export default {
  props: {
    pageSizes: {
      type: Number | String,
      default: 10
    },
    currentPage: {
      type: Number | String,
      default: 1
    },
    pageArray: {
      type: Array,
      default:()=> [10, 15, 20]
    },
    total: {
      type: Number | String,
      default: 0
    },
    layout:{
      type: String,
      default:()=>{
        return 'total, sizes, prev, pager, next,jumper'
      }
    }
  },
  methods: {
    handleSizeChange(e) {
      this.$emit('size-change', e);
    },
    handleCurrentChange(e) {
      this.$emit('tabPageCurrent', e);
    }
  }
};
</script>

<style>
</style>