<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="admlog">
        <div class="box">
            <div class='tit'>
                <div class="left_zhu">
                    时间:
                    <el-date-picker
                        @change="rqi_huanle"
                        style="border:none;"
                        v-model="value1" 
                        type="daterange"
                        :clearable='false'
                        range-separator="至">
                    </el-date-picker>
                </div>
                <div class="right_zhu">
                    <p>操作对象
                        <el-select v-model="caozuo_duixiang" @change="caozuo_duixiang_huanle">
                            <el-option
                            v-for="(i,index) in caozuo_list"
                            :key="index"
                            :label="i.label"
                            :value="i.value">
                            </el-option>
                        </el-select>
                    </p>
                    <p>管理员
                        <el-select v-model="admin_renyuan" @change="ganliyuan_huanle">
                            <el-option
                            v-for="(i,index) in renyuan_list"
                            :key="index"
                            :label="i.label"
                            :value="i.value">
                            </el-option>
                        </el-select>
                    </p>
                </div>
            </div>
            <div class="tit2">
                <p>时间</p>
                <p>管理员</p>
                <p>操作对象</p>
                <p style="border:none;padding:0 1.5rem;">操作详情</p>
            </div>
            <div class="tit3" v-if="rzhi_list.length==0">
                <p style="width:100%;">暂无数据</p>
            </div>
            <div v-if="rzhi_list.length!=0" >
                <div class="tit3"  v-for="(i,index) in rzhi_list" :key="index">
                    <p style="width:1.5rem;">{{i.operate_time}}</p>
                    <p>{{i.username}}</p>
                    <p>{{i.duixiang}}</p>
                    <p style="border:none;width:3.68rem;">{{i.operate_desc}}</p>
                </div>
            </div>
        </div>

        <Pagination :limit="page_size" :currentPage="page_index" :total="dataTotal" @size-change="handleSizeChange" @tabPageCurrent="handleCurrentChange" />
    </div>
</template>

<script>
import { query_ent_sub_manager, query_ent_primary_manager_detail, get_system_logs_list } from '../../api/api.js'
import Pagination from '../../components/Pagination'
export default {
  name: 'admlog',
  data () {
    return {
      ent_id: '',
      value1: [new Date(2019, 11, 1), new Date()],
      formInline: {
        user: '',
        region: ''
      },
      start_sj: '',
      end_sj: '',
      caozuo_duixiang: '全部',
      admin_renyuan: '全部',
      caozuo_list: [
        {
          value: '-1',
          label: '全部'
        },
        {
          value: '0',
          label: '基础信息设置'
        },
        {
          value: '1',
          label: '目标'
        },
        {
          value: '2',
          label: '销售'
        },
        {
          value: '3',
          label: '产品'
        },
        {
          value: '4',
          label: '核算'
        },
        {
          value: '5',
          label: '风控'
        },
        {
          value: '6',
          label: '成长'
        }
      ],
      renyuan_list: [
        {
          value: '0',
          label: '全部',
          id: null
        }
      ],
      rzhi_list: [],
      caozuo_id: null,
      guanli_id: null,
      page_size: 10,
      page_index: 1,
      dataTotal: 0
    }
  },
  components: {Pagination},
  mounted () {
    this.jinyue()
    console.log(this.renyuan_list)
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
    this.jichu()
  },
  methods: {
    // 基础
    jichu () {
      query_ent_primary_manager_detail({
        data: {
          ent_id: this.ent_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10103) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.renyuan_list.push({
            value: date.staff_id,
            label: date.name
          })
        } else if (res.data.code == 10104) {}
      })
      query_ent_sub_manager({
        data: {
          ent_id: this.ent_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10109) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          for (let i = 0; i < date.length; i++) {
            this.renyuan_list.push({
              value: date[i].staff_id,
              label: date[i].name
            })
          }
          // this.renyuan_list=date
        } else if (res.data.code == 10110) {
        }
      })
    },
    // 时间小于10前面加0
    pa (s) {
      return s < 10 ? '0' + s : s
    },
    // 获取本月时间
    jinyue () {
      console.log(this.value1)
      let start = this.value1[0]
      let end = this.value1[1]
      start = start.getFullYear() + '-' + this.pa(start.getMonth() + 1) + '-' + this.pa(start.getDate())
      end = end.getFullYear() + '-' + this.pa(end.getMonth() + 1) + '-' + this.pa(end.getDate())
      this.start_sj = start
      this.end_sj = end
      get_system_logs_list({
        data: {
          ent_id: this.ent_id,
          date_begin: this.start_sj,
          date_end: this.end_sj,
          operate_aim: this.caozuo_id,
          user_id: this.guanli_id,
          page_index: this.page_index + '',
          page_size: this.page_size + ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10201) {
          const date = JSON.parse(res.data.body.data).list
          this.dataTotal = JSON.parse(res.data.body.data).total
          console.log(date)
          for (let i = 0; i < date.length; i++) {
            if (date[i].operate_aim == '0') {
              date[i].duixiang = '基础信息设置'
            } else if (date[i].operate_aim == '1') {
              date[i].duixiang = '目标'
            } else if (date[i].operate_aim == '2') {
              date[i].duixiang = '销售'
            } else if (date[i].operate_aim == '3') {
              date[i].duixiang = '产品'
            } else if (date[i].operate_aim == '4') {
              date[i].duixiang = '核算'
            } else if (date[i].operate_aim == '5') {
              date[i].duixiang = '风控'
            } else if (date[i].operate_aim == '6') {
              date[i].duixiang = '成长'
            }
          }
          this.rzhi_list = date
        } else if (res.data.code == 10202) {
        }
      })
    },
    caozuo_duixiang_huanle () {
      console.log(this.caozuo_duixiang)
      this.rqi_huanle()
    },
    ganliyuan_huanle () {
      console.log(this.admin_renyuan)
      this.rqi_huanle()
    },
    rqi_huanle () {
      if (this.caozuo_duixiang == '全部' || this.caozuo_duixiang === '-1') {
        this.caozuo_id = null
      } else {
        this.caozuo_id = this.caozuo_duixiang
      }
      if (this.admin_renyuan == '全部' || this.admin_renyuan === '0') {
        this.guanli_id = null
      } else {
        this.guanli_id = this.admin_renyuan
      }
      this.jinyue()
    },
    ndleSizeChange(e) {
      this.page_index = 1
      this.page_size = e
      this.jinyue()
    },
    handleCurrentChange(e) {
      this.page_index = e
      this.jinyue()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.admlog {
    height:100%;
    width:100%;
    .box{
        padding:  0.4rem 0.29rem;
        .tit{
            display: flex;
            // justify-content: space-between;
            // align-items: center;
            flex-wrap: wrap;
            .left_zhu{
                font-size: 0.14rem;
                color:#4C4A4D;
                display: flex;
                align-items: center;
            }
            .right_zhu{
                display: flex;
                align-items: center;
                p{
                    font-size: 0.14rem;
                    color:#4C4A4D;
                    display: flex;
                    align-items: center;
                    width: 2.2rem;
                }
            }
        }
        .tit2{
            height:0.59rem;
            background:#E7E8EB;
            margin-top: 0.4rem;
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            p{
                font-size: 0.17rem;
                color:#1A2533;
                padding: 0 0.57rem;
                min-height:0.29rem;
                line-height: 0.29rem;
                border-right: 0.01rem solid #979797;
            }
        }
        .tit3{
            min-height:0.89rem;
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            border-bottom: 0.01rem solid #EEEEEE;
            cursor:pointer;
            &:hover{
                background: rgb(250, 249, 249);
            }
            p{
                font-size: 0.14rem;
                color:#1A2533;
                width: 1.77rem;
                text-align: center;
                min-height:0.29rem;
                line-height: 0.29rem;
            }
        }
    }
}
</style>
